<template>
    <div>
        <van-nav-bar
                class="Vip-header"
                placeholder
                fixed
                left-arrow
                :border='false'
                z-index="99"
                @click-left="leftClick">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#fff"/>
            </template>
            <template #title>
                <p class="header_title">开通会员</p>
            </template>
        </van-nav-bar>
        <div>
            <div class="vip_card">
                <img src="../../assets/images/club_card_bg2.png" alt="" class="club_card_bg">
                <template v-if="user_infoList.is_vip == '1'">
                    <div class="username">
                        <div class="is_vip">
                            <img :src="user_infoList.avatar" alt="" class="set_def_tx">
                            <div>
                                <p class="my_title">{{user_infoList.nickname}}</p>
                                <p class="open-title" v-if="user_infoList.viptime">
                                    畅读VIP会员至{{user_infoList.viptime}}到期</p>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="user_infoList.is_vip == '0'">
                    <div class="username">
                        <p><img src="../../assets/images/vip_tit_img.png" alt="" class="vip_tit_img"></p>
                        <p class="vip_tit_img_title">开通享有所有畅读VIP特权</p>
                    </div>
                </template>
                <p class="vip-button" @click="openclick">{{user_infoList.is_vip == '1'?'立即续费':'立即开通'}}</p>
            </div>
            <p class="title">会员权益</p>
            <div class="vip_privilege_list">
                <template v-for="item in vipList.vip_privilege">
                    <div class="vip_privilege">
                        <p><img :src="item.image" alt="" class="image"></p>
                        <p class="vip_privilege_title">{{item.title}}</p>
                    </div>
                </template>
            </div>
            <p class="xian"></p>
            <p class="title">选择套餐</p>
            <div class="package_list">
                <template v-for="item in vipList.package">
                    <div class="package" :class="item.id == index_id.id?'active':''" @click="create_order(item)">
                        <p class="package_title1">{{item.title}}</p>
                        <p class="package_title2">{{item.price}}</p>
                        <p class="package_title3"
                           :class="item.original_price ==0.00?'package_title3_active':''">
                            {{item.original_price}}</p>
                        <p class="package_title4" v-if="item.sday != 0">送{{item.sday}}天</p>
                    </div>
                </template>
            </div>
            <div class="open">
                <p class="open_left"><span>尊享会员</span> 米多小说</p>
                <p class="open_button" @click="openclick">立即开通</p>
            </div>
        </div>
        <!--        支付方式-->
        <van-popup v-model="show" position="bottom" @close="showClick">
            <div class="top-list">
                <div @click="weixin">
                    <p>
                        <img src="../../assets/images/upweixin.png" alt="" class="upweixin">
                    </p>
                    <p class="top-text">微信支付</p>
                </div>
                <div @click="zfb" v-if="!userAgent">
                    <p>
                        <img src="../../assets/images/upzfb.png" alt="" class="upweixin">
                    </p>
                    <p class="top-text">支付宝支付</p>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "Vip",
        data() {
            return {
                index_id: '',
                show: false,
                vipList: [],
                userAgent: true,
                fromPath: '',
                user_infoList: [],
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.fromPath = from.path
            })
        },
        created() {
            this.base_info()
            this.user_info()
            if (/MicroMessenger/.test(window.navigator.userAgent)) {
                //在微信中
                this.userAgent = true
            } else {
                //在浏览器中打开
                this.userAgent = false
            }
        },
        methods: {
            user_info() {
                this.$axios.get('/api/user_info')
                    .then(res => {
                        var res = res.data.data
                        this.user_infoList = res
                    })
            },
            leftClick() {
                if (this.fromPath == '/my') {
                    this.$router.replace({
                        path: '/my'
                    })
                } else {
                    this.$router.go(-1)
                }
            },

            openclick() {
                this.$toast('请选择需要开通的套餐！')
            },
            create_order(item) {
                this.show = true
                this.index_id = item
            },
            showClick() {
                this.index_id = ''
            },
            base_info() {
                this.$axios.post('/api/vip/base_info')
                    .then(res => {
                        var res = res.data.data
                        this.vipList = res
                    })
            },

            weixin() {
                this.$toast('微信支付')
                if (this.userAgent) {
                    //微信内置支付
                    this.$axios.post('/api/vip/create_order', {
                        vip_id: this.index_id.id,
                        pay_type: '1',
                        scene: 'h5',
                        scene_type: 'wechat',
                    })
                        .then(res => {
                            this.show = false
                            this.index_id = ''
                            this.onBridgeReady(res.data.data)
                        })
                } else {
                    //浏览器 微信支付
                    this.$axios.post('/api/vip/create_order', {
                        vip_id: this.index_id.id,
                        pay_type: '1',
                        scene: 'h5',
                    })
                        .then(res => {
                            window.location.href = res.data.data.mweb_url
                        })
                }
            },
            //公众号支付 微信支付
            onBridgeReady(res) {
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": res.appId,     //公众号名称，由商户传入
                        "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                        "nonceStr": res.nonceStr, //随机串
                        "package": res.package,
                        "signType": res.signType,         //微信签名方式：
                        "paySign": res.paySign, //微信签名
                    }, res => {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            this.$toast.success('充值成功')
                            this.$router.go(-1)
                        }
                    }
                );
            },
            //公众号支付 微信支付 end
            //支付宝支付
            zfb() {
                this.$toast('支付宝支付')
                if (this.userAgent) {
                    //微信内置浏览器 支付宝不可支付 这里不做任何操作
                } else {
                    //浏览器 支付宝支付
                    this.$axios.post('/api/vip/create_order', {
                        vip_id: this.index_id.id,
                        pay_type: '2',
                        scene: 'h5',
                    })
                        .then(res => {
                            const div = document.createElement('div');
                            div.innerHTML = res.data.message
                            document.body.appendChild(div)
                            document.forms[0].submit()
                        })
                }
            },
        }
    }
</script>

<style scoped>
    .vip_tit_img_title {
        font-size: 24px;
        color: #896B36;
        margin-top: 10px;
    }

    .vip_tit_img {
        width: 200px;
        height: 60px;
    }

    .is_vip {
        display: flex;
        align-items: center;
    }

    .set_def_tx {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: url("../../assets/images/set_def_tx.png") no-repeat;
        background-size: cover;
        display: inline-block;
        margin-right: 10px;
    }

    .open-title {
        color: #896B36;
        font-size: 24px;
        margin-top: 20px;
    }

    .my_title {
        color: #C09041;
        font-size: 32px;
    }

    .username {
        position: absolute;
        top: 80px;
        left: 80px;

    }

    .top-text {
        color: #BFC2CC;
        font-size: 20px;
        text-align: center;
    }

    .top-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 60px 40px;
    }

    .upweixin {
        width: 107px;
        height: 127px;
    }

    .active {
        border: 1px solid #FEC35E !important;
        background: rgba(254, 250, 243, 1);
    }

    .open_button {
        position: absolute;
        right: -1px;
        top: 0;
        background: linear-gradient(135deg, rgba(250, 227, 181, 1) 0%, rgba(246, 211, 151, 1) 100%);
        border-radius: 44px;
        color: #564523;
        font-size: 30px;
        padding: 25px 40px;
    }

    .open_left {
        color: #F9E8E0;
        font-size: 28px;
    }

    .open_left > span {
        font-weight: bold;
    }

    .open {
        padding: 25px 40px;
        margin: 0 40px 80px;
        position: relative;
        background: #2B2628;
        border-radius: 44px;
        display: flex;
        align-items: center;
    }

    .package_title3_active {
        opacity: 0;
    }

    .package_title4 {
        font-size: 20px;
        color: rgba(255, 255, 255, 1);
        background: #2c2934;
        padding: 10px;
        position: absolute;
        top: -20px;
        left: 0;
        border-radius: 10px 0 10px 0;
    }

    .package_title3 {
        font-size: 24px;
        font-weight: 400;
        color: rgba(191, 194, 204, 1);
        text-decoration: line-through;
    }

    .package_title2 {
        margin: 30px 0;
        height: 30px;
        font-size: 40px;
        font-weight: normal;
        color: rgba(228, 191, 129, 1);
        line-height: 45px;
    }

    .package_title1 {
        height: 29px;
        font-size: 32px;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
        line-height: 45px;
    }

    .package_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 40px 60px;
    }

    .package {
        border: 1px solid #ECECEC;
        border-radius: 10px;
        padding: 40px;
        position: relative;
        text-align: center;
        margin-bottom: 40px;
        width: 29%;
    }

    .vip_privilege_title {
        font-size: 28px;
        color: #606266;
        margin-top: 20px;
    }

    .image {
        width: 102px;
        height: 99px;
    }

    .vip_privilege_list {
        display: flex;
        padding: 0 50px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .vip_privilege {
        margin-right: 120px;
        text-align: center;
        margin-bottom: 46px;
    }

    .vip_privilege:nth-of-type(3n+3) {
        margin-right: 0 !important;
    }

    .title {
        padding: 60px 40px;
        color: #303133;
        font-size: 32px;
        text-align: center;
    }

    .vip_card {
        position: relative;
    }

    .vip-button {
        color: #564523;
        padding: 20px 40px;
        background: linear-gradient(135deg, rgba(243, 220, 172, 1) 0%, rgba(245, 207, 142, 1) 100%);
        border-radius: 50px;
        text-align: center;
        position: absolute;
        bottom: 33px;
        left: 80px;
        font-size: 30px;
    }

    .header_title {
        color: #fff;
    }

    .club_card_bg {
        width: 100%;
        background: #000;
        padding: 40px 40px 0px 40px;
        display: inline-block;
        height: 300px;
    }


</style>